import React,{useState,useEffect} from 'react'
import {Link,navigate } from 'gatsby'
import Layout from '../layouts'
import Helmet from 'react-helmet'
import Content,{HTMLContent} from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
export const CaseDetailTemplate = ({location,uncolorimage, colorimage, caserefurl, casetitle, casedesc, casecontent,prev_next,contentComponent}) => {
  const PageContent = contentComponent || Content

  return (
    <React.Fragment>
      <Helmet defer={false}>
      <title>{`${casetitle} | JDSolutions`}</title>
      <meta property="og:title" content={`${casetitle} | JDSolutions`} />
        <meta property="og:type" content="case" />
        <meta property="og:image" content={colorimage} />     
      </Helmet>
    <div className="row-fluid-wrapper row-depth-1 row-number-1 ">
    <div className="row-fluid ">
    <div className="span12 widget-span widget-type-cell case-details">
    <div className="row-fluid-wrapper row-depth-1 row-number-2 ">
    <div className="row-fluid ">
    <div className="span12 widget-span widget-type-cell wrapper">
    <div className="row-fluid-wrapper row-depth-1 row-number-4 ">
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-raw_jinja ">
        <Link to="/cases" className="back-list en-text" ><span>Back to Cases</span></Link >
        <div className="next-prev-case">
          <Link 
          to={prev_next[0].previous !== null ? prev_next[0].previous.fields.slug : null} 
          className={`next-list en-text ${prev_next[0].previous === null ? `disable`:``}`}>
            <span>Previous case</span></Link>
          <Link to={prev_next[0].next !== null ? prev_next[0].next.fields.slug : null} 
          className={`prev-list en-text ${prev_next[0].next === null ? `disable`:``}`} >
            <span>Next case</span></Link>
        </div>
        </div>        
        </div>
    </div>
    <div className="row-fluid-wrapper row-depth-1 row-number-5 ">
      <div className="row-fluid ">
      <div className="span4 widget-span widget-type-cell cases-left-col">

<div className="row-fluid-wrapper row-depth-1 row-number-6 ">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-cell thumbnail company1">

<div className="row-fluid-wrapper row-depth-2 row-number-1 ">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-linked_image non_active_logo" >
<div className="cell-wrapper layout-widget-wrapper">
<span id="hs_cos_wrapper_module_1509296086831231" 
className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_linked_image"  
>
<PreviewCompatibleImage
            imageInfo={{
                childImageSharp: uncolorimage.childImageSharp,
                alt: `${casetitle}_uncolor`
                }}
            />
{/* <img src={uncolorimage}
className="hs-image-widget " 
alt={`${casetitle}_uncolor`}
title={casetitle}/> */}
</span>
</div>
</div>
</div>
</div>

<div className="row-fluid-wrapper row-depth-2 row-number-2 ">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-linked_image active_logo">
<div className="cell-wrapper layout-widget-wrapper">
<span id="hs_cos_wrapper_module_1509296092279232" 
className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_linked_image" >
  <PreviewCompatibleImage
            imageInfo={{
                childImageSharp: colorimage.childImageSharp,
                alt: `${casetitle}_uncolor`
                }}
            />
    {/* <img src={colorimage} className="hs-image-widget "
     alt={`${casetitle}_color`}
     title={casetitle} /> */}
     </span></div>
</div>
</div>
</div>

</div>
</div>
</div>

<div className="row-fluid-wrapper row-depth-1 row-number-1 ">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-rich_text content">
<div className="cell-wrapper layout-widget-wrapper">
<span id="hs_cos_wrapper_module_1509296031895220" 
className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text">
<div className="en-text">
<h4><a href={caserefurl} 
target="_blank">{casetitle} </a>
</h4>
<p>{casedesc}</p>
</div>

</span>
</div>
</div>
</div>
</div>
</div>


<div className="span8 widget-span widget-type-cell cases-right-col">
    <div className="row-fluid-wrapper row-depth-1 row-number-2 ">
        <div className="row-fluid ">
            <div className="span12 widget-span widget-type-rich_text detail">
                <div className="cell-wrapper layout-widget-wrapper">
                    <span className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text">
                    <div className="en-text">
                    <PageContent className="content" content={casecontent} />
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
    </div>

    </div>
      </div>
      </div>
      </div>
      </div>
      </div>
</div>
      </React.Fragment>
  )
}


const CaseDetail = ({data,location}) => {
  const [page, setPage] = useState('case-detail formal')
  return (
    <Layout page={page}>
      <CaseDetailTemplate
      location={location}
       uncolorimage={data.markdownRemark.frontmatter.uncolorfeaturedimage}
       colorimage={data.markdownRemark.frontmatter.colorfeaturedimage}
       caserefurl={data.markdownRemark.frontmatter.caserefurl}
       casetitle={data.markdownRemark.frontmatter.title}
       casedesc={data.markdownRemark.frontmatter.description}
       casecontent={data.markdownRemark.html}
       prev_next={data.allMarkdownRemark.edges.filter(el=>(el.node.id === data.markdownRemark.id))}
       contentComponent={HTMLContent}/>
    </Layout>
  )
}



export default CaseDetail

export const pageQuery = graphql`
  query CaseByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        caserefurl
        description
        uncolorfeaturedimage {
            childImageSharp{
                    fluid{
                      srcSet
                    }
                  }
        }
        colorfeaturedimage {
            childImageSharp{
                    fluid{
                      srcSet
                    }
                  }
        }
      }
    }
    allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "case-detail"}}}) {
      edges {
        node {
          id
          fields {
            slug
          }
        }
        previous {
          fields {
            slug
          }
        }
      next {
          fields {
            slug
          }
        }
      }
    }
  }
  
`
